// 营销
import PromotionsManager from '@/views/marketing/PromotionsManager.vue'
import PromotionsDetails from '@/views/marketing/PromotionsDetails.vue'
import GiftManager from '@/views/marketing/GiftManager.vue'
import CouponManager from '@/views/marketing/CouponManager.vue'
import CouponTimingManager from '@/views/marketing/CouponTimingManager.vue'
import BannerConfigure from '@/views/marketing/BannerConfigure.vue'
import RecommendConfigure from '@/views/marketing/RecommendConfigure.vue'

import GiftDetails from '@/views/marketing/GiftDetails.vue'
import GiftCodeManager from '@/views/marketing/GiftCodeManager.vue'
import CouponDetails from '@/views/marketing/CouponDetails.vue'
import CouponTimingDetails from '@/views/marketing/CouponTimingDetails.vue'
import ImportGiftCode from '@/views/marketing/ImportGiftCode.vue'
import CouponGrantRecord from '@/views/marketing/CouponGrantRecord.vue'

import MessageManage from "@/views/marketing/MessageManage.vue"
import MessageDetails from "@/views/marketing/MessageDetails.vue"
import TravelDetails2 from "@/views/travel/TravelDetails2.vue";
import TravelManager2 from "@/views/travel/TravelManager2.vue";
import TravelInput2 from "@/views/travel/TravelInput2.vue";

import CategoryManager from "@/views/marketing/CategoryManager.vue"
import CategoryProductManager from "@/views/marketing/CategoryProductManager.vue"

export default [{
    path: '/promotionsmanager',
    name: 'promotionsmanager',
    component: PromotionsManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/promotionsmanager'
    }
}, {
    path: '/promotionsdetails',
    name: 'promotionsdetails',
    component: PromotionsDetails,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/promotionsmanager'
    }
}, {
    path: '/giftmanager',
    name: 'giftmanager',
    component: GiftManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/giftmanager'
    }
}, {
    path: '/giftdetails',
    name: 'giftdetails',
    component: GiftDetails,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/giftmanager'
    }
}, {
    path: '/giftcodemanager',
    name: 'giftcodemanager',
    component: GiftCodeManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/giftmanager'
    }
}, {
    path: '/importGiftCode',
    name: 'importGiftCode',
    component: ImportGiftCode,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/giftmanager'
    }
}, {
    path: '/couponmanager',
    name: 'couponmanager',
    component: CouponManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/couponmanager'
    }
}, {
    path: '/couponTimingManager',
    name: 'couponTimingManager',
    component: CouponTimingManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/couponmanager'
    }
}, {
    path: '/coupondetails',
    name: 'coupondetails',
    component: CouponDetails,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/couponmanager'
    }
}, {
    path: '/couponGrantRecord',
    name: 'couponGrantRecord',
    component: CouponGrantRecord,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/couponmanager'
    }
}, {
    path: '/couponTimingDetails',
    name: 'couponTimingDetails',
    component: CouponTimingDetails,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/couponmanager'
    }
}, {
    path: '/bannerconfigure',
    name: 'bannerconfigure',
    component: BannerConfigure,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/bannerconfigure'
    }
}, {
    path: '/recommendconfigure',
    name: 'recommendconfigure',
    component: RecommendConfigure,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/recommendconfigure'
    }
}, {
    path: '/categoryManager',
    name: 'categoryManager',
    component: CategoryManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/CategoryManager'
    }
}, {
    path: '/messagemanage',
    name: 'messagemanage',
    component: MessageManage,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/messagemanage'
    }
}, {
    path: '/categoryProductManager',
    name: 'categoryProductManager',
    component: CategoryProductManager,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/categoryManager'
    }
}, {
    path: '/messagedetails',
    name: 'messagedetails',
    component: MessageDetails,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/messagemanage'
    }
},{
    path: '/traveldetails2',
    name: 'traveldetails2',
    component: TravelDetails2,
    meta: {
        activeMainMenu: 'travel',
        activeSubMenu: '/travelinput'
    }
}, {
    path: '/travelmanager2',
    name: 'travelmanager2',
    component: TravelManager2,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/travelmanager2'
    }
}, {
    path: '/travelinput2',
    name: 'travelinput2',
    component: TravelInput2,
    meta: {
        activeMainMenu: 'marketing',
        activeSubMenu: '/travelinput2'
    }
},
]
